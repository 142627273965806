<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>订单管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onBackList">未交付订单</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="onDetail(orderSn)">订单详情</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>关联订单列表</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="commodity-container">
      <a-row>
        <a-col><div class="info-title">关联订单</div></a-col>
      </a-row>
      <a-table
        class="commodity-table"
        :data-source="tableData"
        :columns="tableColumns"
        :loading="loading"
        :row-key="
          (record, index) => {
            return index;
          }
        "
        :pagination="false"
      >
        <template slot="orderSn" slot-scope="text, record">
          <div class="ordersn-container">
            <div class="order-sn">
              <img
                v-if="!record.porderSn"
                src="../../assets/order/icon-forword.png"
                class="icon-forword"
              />
              {{ record.orderSn }}
            </div>
            <div v-if="record.porderSn" class="order-forword">
              <img
                src="../../assets/order/icon-forword.png"
                class="icon-forword"
              />
              {{ record.porderSn }}
            </div>
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="onDetail(record.orderSn)">查看详情</a>
        </template>
      </a-table>
    </section>
  </a-layout-content>
</template>

<script>
import { fetchRelationOrder } from "../../services/OrderService";
import { tsFormat } from "../../components/DateUtils";

const tableColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    scopedSlots: { customRender: "orderSn" }
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "经销商",
    dataIndex: "businessVenuName"
  },
  {
    title: "订单状态",
    dataIndex: "orderStatus"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  data() {
    return {
      tableData: [],
      tableColumns,
      loading: false,
      orderSn: this.$route.params.orderSn
    };
  },
  mounted() {
    this.loadDetail();
  },
  methods: {
    onBackList() {
      this.$router.push({ name: "UndeliveredOrder" });
    },
    onDetail(orderSn) {
      this.$router.push({
        name: "UndeliveredDetail",
        params: { procureOrderSn: orderSn }
      });
    },
    loadDetail() {
      this.loading = true;
      fetchRelationOrder(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.loading = false;
          const data = resp.data.data;
          if (data.length > 0) {
            data.forEach(item => {
              item.createTime = tsFormat(item.createTime);
            });
          }
          this.tableData = data;
        }
      });
    }
  }
};
</script>

<style scoped>
.commodity-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}
.info-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.ordersn-container {
  display: flex;
  flex-direction: column;
}
.icon-forword {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.order-forword {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
.order-sn {
  display: flex;
  align-items: center;
}
</style>
